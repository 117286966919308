/* RESET */
/* prettier-ignore */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
/* prettier-ignore */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/*DEFAULT */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* CUSTOM */
* {
  margin: 0;
}
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@font-face {
  font-family: Folks;
  src: url(../../fonts/Folks-Normal.ttf);
}

@font-face {
  font-family: Folks_bold;
  src: url(../../fonts/Folks-Bold.ttf);
}

:root {
  --green: #266e45;
  --light_green: #78b994;
}

h1 {
  font-size: 3rem;
  font-family: Folks, sans-serif;
  color: #266e45;
  font-weight: bold;
  letter-spacing: 1px;
}

h2 {
  font-size: 2.5rem;
  font-family: Folks, sans-serif;
  color: #266e45;
  font-weight: bold;
  letter-spacing: 1px;
}

h3 {
  font-size: 2rem;
  font-family: Folks, sans-serif;
  color: #266e45;
  font-weight: bold;
  letter-spacing: 1px;
}

h4 {
  font-size: 1.3rem;
  font-family: Folks_bold, sans-serif;
  color: #266e45;
  font-weight: bolder;
}

p {
  font-family: Roboto;
  color: #383838;
}

label {
  font-family: Roboto;
  color: #383838;
}

input[type="text"],
input[type="password"],
input[type="date"],
input[type="number"],
input[type="email"],
input[type="time"] {
  border-radius: 6px;
  height: 27px;
  font-size: 0.95rem;
  padding: 0 10px;
  border: 1px solid #b1b1b1;
  color: #383838;
  outline: none;
  box-sizing: border-box;
  min-width: 0px;
  width: 100%;
}

input[type="text"]:disabled {
  background-color: #ededed;
  color: rgb(163, 163, 163);
  border: 1px solid #e9e9e9;
}

input[type="date"],
input[type="time"] {
  font-size: 13px !important;
}

textarea {
  border-radius: 6px;
  font-size: 0.95rem;
  padding: 0 10px;
  border: 1px solid #b1b1b1;
  color: #383838;
  outline: none;
  box-sizing: border-box;
  min-width: 0px;
  width: 100%;
  min-height: 100px;
  font-family: Roboto;
}

button {
  padding: 7px 30px;
  border-radius: 99px;
  background-color: #266e45;
  border: 2px solid #266e45;
  color: white;
  font-family: Folks, sans-serif;
  font-size: 1.2rem;
  outline: none;
}

.button_appereance {
  border-radius: 99px;
  background-color: #266e45;
  border: 2px solid #266e45;
  color: white;
  outline: none;
}

.button_appereance:hover {
  cursor: pointer;
}

button:hover {
  background-color: white;
  color: #266e45;
  outline: none;
  border: 2px solid #266e45;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}

.button_delete {
  padding: 7px 30px;
  border-radius: 99px;
  background-color: #ff1616;
  border: 2px solid #ff1616;
  color: white;
  font-family: Folks, sans-serif;
  font-size: 1.2rem;
  outline: none;
}

.button_delete:hover {
  background-color: #ff1616;
  color: #000000;
  border: 2px solid #ff1616;
  outline: none;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}

select {
  border-radius: 10px;
  height: 32px;
  font-size: 1rem;
  padding: 0 10px;
  border: 1px solid #707070;
  color: #383838;
  outline: none;
  box-sizing: border-box;
  min-width: 0px;
  width: 100%;
}

.modal_select {
  border-radius: 6px;
  height: 27px;
  font-size: 0.95rem;
  padding: 0 10px;
  border: 1px solid #b1b1b1;
  color: #383838;
  outline: none;
  box-sizing: border-box;
  min-width: 0px;
  width: 100%;
}

.bold {
  font-weight: bold;
}

a {
  text-decoration: none;
}

table {
  font-family: Roboto, sans-serif;
}

th {
  font-weight: bold;
}

td {
  border: 1px solid #383838;
}

th {
  border: 1px solid #383838;
  padding: 5px;
}

/* GENERAL SECTIONS */

.container__clients {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  /* background-color: cadetblue; */
}

.img__icon {
  margin-bottom: 3%;
  /* background-color: chocolate; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 180px;
}

.img__icon > img {
  height: 115px;
  object-fit: contain;
  margin-bottom: 20px;
}

.img__icon_fa {
  font-size: 80px;
  margin-bottom: 20px;
}

.buttons_access {
  /* background-color: cyan; */
  display: flex;
  width: 100%;
  justify-content: center;
}

.square__button {
  background-color: #266e45;
  height: 157px;
  width: 173px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 30px 0px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
}

.square__button:hover {
  background-color: #78b994;
  transition: 0.2s ease-in-out;
}

.white {
  color: white;
}

.icon__clientshome {
  font-size: 60px;
}

.search__menu {
  width: 100%;
  display: flex;
  /* background-color: darkkhaki; */
  justify-content: center;
  padding: 1% 0%;
}

.search__by {
  display: flex;
  flex-direction: column;
  margin-right: 10%;
  /* background-color: greenyellow; */
  flex: 0.5;
}

.search__by__contain {
  align-self: flex-end;
}

.search__input {
  /* background-color: violet; */
  flex: 0.5;
}
.select__menu {
  /* background-color: tomato; */
  max-width: 300px;
}
.basic-single {
  max-height: 32px !important;
}

.tagSearch {
  margin-bottom: 15px;
}

.inputRadio {
  height: auto;
  margin-right: 10px;
  margin-bottom: 10px;
}

.screen {
  display: flex;
  box-sizing: border-box;
}

.content {
  margin: 10px;
  display: flex;
  width: 100%;
  /* background-color: burlywood; */
}

.col_white {
  color: white;
}

.col_green {
  color: var(--green);
}

.flex_space_evenly {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}

.flex_space_between {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.simple_flex {
  display: flex;
  align-items: center;
}

/* This duplicate exists due to 'simple_flex' was used in a wrong way and we dont want to break thinks  */
.simple_flex_ {
  display: flex;
  align-items: center;
}


.simple_flex_column {
  display: flex;
  flex-direction: column;
}


.p_bold {
  font-weight: bold;
}

.blocked_color {
  color: #e6e6e6;
}

.blocked_color2 {
  background-color: #b0b0b0;
}

.input_center {
  text-align: center;
}

.simple_select {
  border-radius: 6px;
  height: 27px;
  font-size: 0.95rem;
  padding: 0 10px;
  border: 1px solid #b1b1b1;
  color: #383838;
  outline: none;
  box-sizing: border-box;
  min-width: 0px;
  width: 100%;
}