.container__tasks {
  width: 120%;
  flex-direction: column;
  margin-left: -10px;
}

.search_container > input {
  max-width: 500px;
  margin-right: 10px;
}
.email_container > input {
  width: 170px;
  background-color: #383838;

  margin-left: 10px;
}

.guides {
  flex: 0.4;
  display: flex;
  justify-content: space-around;
}

.table_tasks {
  width: 100%;
}

.thcodigo {
  width: 6%;
}

.thestado {
  width: 3%;
}

.thCliente {
  width: 17%;
}

.thContacto {
  width: 17%;
}

.thUltimoSgto {
  width: 12%;
}

.thProxSgto {
  width: 12%;
}
.thPrioridad {
  width: 8%;
}
.thNoCot {
  width: 11%;
}
.thActualizar {
  width: 7%;
}
.thDetalle {
  width: 50%;
}

.rowHeaders {
  background-color: #383838;
  color: white;
}

.rowHeaders > th {
  border: none;
  position: sticky;
  top: 125px;
  z-index: 1;

  background-color: #383838;
}

.rowTasks > td {
  border-top-width: 1px;
  border-top-color: #e5e5e5;
  border-bottom-width: 1px;
  border-bottom-color: #e5e5e5;
  font-size: 14px;
  border-left: none;
  border-right: none;
}

.wrapper-estado {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 3px 0 5px;
}

.wrapper-icon-codigo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.square_estado {
  width: 11px;
  height: 11px;
  border-radius: 90px;
}

.rowTasks {
  height: 35px;
}

.iconRow {
  font-size: 21px;
}

.detailRow {
  border: none !important;
  background-color: #e5e5e5;
  padding: 10px 15px;
}

.detail-line1 {
  display: flex;
  width: 100%;
  /* background-color: green; */
}

.p_detail {
  font-size: 13px;
  font-weight: bold;
  margin-left: 15px;
  /* background-color: pink; */
}
.line1__ultimoSgto,
.line1__proxSgto,
.line1__estado {
  display: flex;
  flex: 0.33;
  align-items: center;
}
.line1v1__estado {
  display: flex;
  flex: 0.2;
  align-items: center;
}
.line5__estado {
  display: flex;
  flex: 0.33;
  align-items: center;
  /* background-color: blue; */
}

.line1__ultimoSgto > input,
.line1__proxSgto > input,
.line1__estado > select,
.line1__estado > input {
  max-width: 150px;
  margin-left: 15px;
  height: 25px;
  font-size: 13px;
  /* background-color: blue; */
}
.lineemail__estado {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;

  /* background-color: blue; */
}

.inputRadio__addclienttask {
  margin-right: 0px;
  height: auto;
}

.detail-line2 {
  display: flex;
  width: 100%;
  margin-top: 20px;
  min-height: 25px;
}

.detail-line3 {
  display: flex;
  margin-top: 10px;
  width: 100%;
  /* background-color: pink; */
}

.line3__ultimoSgtotxt {
  display: flex;
  flex: 0.5;
  align-items: center;
}

.line3__btn {
  flex: 0.5;
  vertical-align: middle;
}
.line4__btn {
  flex: 1;
  align-items: center;
  background-color: coral;
}

.txtareaUltimoSgto {
  max-width: 600px;
  resize: none;
  border: 1px solid #383838;
}

.detail-line4 {
  margin: 10px 0;
  text-align: left;
}
.detail-lineemail {
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: red;
}

.selectPrioridad {
  border: none;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  text-align: center;
  text-align-last: center;
  -moz-text-align-last: center;
  text-decoration: underline;
}

.rowHeaders > th:hover {
  text-decoration: underline;
}

.sub_guide {
  display: flex;
  align-items: center;
  /* background-color: brown; */
}

.contract_modal {
  background-color: white;
  box-sizing: border-box;
  padding: 15px;
}
.email_modal {
  background-color: white;
  box-sizing: border-box;
  padding: 15px;
  align-items: center;
  justify-content: center;
}
.email_modal_form {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: cadetblue; */
  margin: 15px 0;
}

.contract_modal_form {
  display: flex;
  justify-content: space-evenly;
  /* background-color: cadetblue; */
  margin: 15px 0;
}

.contract_modal_form_column {
  background-color: white;
  flex: 0.4;
}
.email_modal_form_column {
  background-color: white;
  flex: 1;
}

.p_contract_form {
  color: black;
  margin-bottom: 3px;
}

.input_contract_form {
  margin-bottom: 10px;
}

.email_modal_form_column_top {
  display: flex;
  justify-content: space-between;
}
