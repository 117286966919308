.header_container {
  display: flex;
  /* max-width: 1024px; */
  width: 100%;
  background-color: #515151;
  min-height: 218px;
  padding: 10px 0px;
}

.logopart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.logopart > h4,
.flex_info_cotizacion > h4 {
  color: white;
  margin-bottom: 5px;
}

.regular_weight {
  font-weight: normal;
}

.infopart {
  flex: 1;
  padding-top: 30px;
  /* background-color: blueviolet; */
  margin-left: 50px;
}

.infopart > h3 {
  color: white;
  letter-spacing: 15px;
}

.flex_info_cotizacion {
  display: flex;
  /* background-color: brown; */
  margin-bottom: 10px;
}
