.pagination {
  color: "green";
  display: flex;
}

.page-item {
  font-weight: bold;
  padding: 2px 8px;
  cursor: pointer;
  color: #383838;
}

.active-page {
  background-color: #266e45;
  color: white;
  border-radius: 99px;
}
