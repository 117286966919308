


.input-lock{
    background-color: #b1b1b1;
}

.main_container_group_management {
    box-sizing: border-box;
    padding: 10px;
    position: relative;
  }