.modal_maintenance_container {
}

.flex_label_input {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  max-width: 290px;
  justify-content: space-between;
}

.label_input_modal {
  font-size: 14px;
  font-weight: bold;
  flex: 0.4;
}

.input_modal {
  flex: 0.6;
  border-radius: 5px !important;
  height: 25px !important;
  font-size: 13px !important;
  padding: 0 10px;
  box-sizing: border-box !important;
}

.input_ref {
  border-radius: 5px !important;
  height: 25px !important;
  font-size: 13px !important;
  padding: 0 10px;
  box-sizing: border-box;
}

.container_inputs_ref {
  display: flex;
  flex: 0.6;
  justify-content: space-between;
}

.select_modal {
  flex: 0.6;
  height: 25px;
  border-radius: 5px;
  border: 0px;
  padding: 0 10px;
  box-sizing: border-box;
  border: 1px solid #797979;
}
