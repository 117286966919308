.modal_return_container {
  padding: 25px 25px 0 25px;
}

.flex_checkbox_return_modal {
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.flex_checkbox_return_modal:last-of-type {
  margin-bottom: 0px;
}
