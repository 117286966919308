.rectangle {
  background-color: #266e45;
  width: 154px;
  height: 97vh;
  margin-top: 12px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.rectangle__flex-home {
  flex: 0.2;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: blue; */
}

.rectangle__flex-others__center {
  flex: 0.6;
  display: flex;
  justify-content: center;
  /* background-color: indigo; */
}

.rectangle__flex-others__vertical {
  margin: 1rem 0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  /* background-color: blue; */
}

.rectangle__flex-logo {
  flex: 0.2;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: blue; */
}

.icon {
  font-size: 40px;
  cursor: pointer;
}

.sidebar_logo_cn {
  width: 98px;
  object-fit: contain;
  align-self: flex-end;
  margin-bottom: 15px;
}

.active {
  height: 55px;
  width: 55px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #78b994;
  transition: 0.3s ease-in-out;
}

.no-active {
  height: 55px;
  width: 55px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrap {
  height: 100%;
  max-height: 100vh;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrap_in_tasks {
  height: 100vh;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #266e45;
}
