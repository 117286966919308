.container__quoteadd {
  /* background-color: skyblue; */
  width: 100%;
}

.div-header-quote {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: thistle; */
  margin-bottom: 10px;
}

.header-logo-btns {
  display: flex;
  align-items: center;
}

.logo-name {
  margin-right: 50px;
}

.logo-name > img {
  margin-bottom: 10px;
}

.quoteadd_flex {
  display: flex;
  max-width: 900px;
}

.quoteadd_flex__7 {
  flex: 0.7;
}

.quoteadd_flex__3 {
  flex: 0.3;
  margin-left: 20px;
}

.quoteadd_flex__6 {
  flex: 0.6;
}

.quoteadd_flex__2 {
  flex: 0.2;
  margin-left: 20px;
}

.flex_custom_select_pdf{
  flex: 0.2;
  margin-top: 8px;
  margin-left: 20px;
  display: flex;
  align-items: center;
}

.quoteadd_flex__4 {
  flex: 0.4;
  margin-left: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.input__table {
  border-radius: 0px !important;
  border: 0px !important;
}

.select__table {
  border-radius: 0px !important;
  border: 0px !important;
  padding: 6px 10px;
}

td {
  text-align: center;
  vertical-align: middle;
}

td > textarea {
  min-height: 70px;
}

.subtotals {
  display: flex;
  flex-direction: column;
  margin: 25px 0px;
}

.subtotal {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 15px;
}

.label-subtotal {
  flex: 0.8;
  /* background-color: thistle; */
  text-align: end;
  margin-right: 10px;
}

.mount-subtotal {
  flex: 0.1;
  /* background-color: rgb(191, 216, 195); */
  text-align: center;
  min-width: 150px;
}

.div_delete {
  cursor: pointer;
}

.thno {
  width: 2%;
}
.thitem {
  width: 8%;
}
.thtipo {
  width: 9%;
}
.thproducto {
  width: 10%;
}
.thdescripcion {
  width: 19%;
}
.thcant {
  width: 2%;
}
.thvlrUnit {
  width: 9%;
}
.thvlrBruto {
  width: 11%;
}
.thdcto {
  width: 2%;
}
.thiva {
  width: 2%;
}
.thvlrTotal {
  width: 11%;
}
.thdelete {
  width: 2%;
}

.div_button_list {
  display: flex;
  flex-direction: row;
  max-height: 32px;
  background-color: #266e45;
  border-radius: 90px;
  padding: 0 10px;
  cursor: pointer;
}

.button-arrow > select {
  width: 80px;
  border-radius: 0px 90px 90px 0px !important;
  border-top: none !important;
  border-bottom: none !important;
  border-right: none;
  width: 20px;
  /* background-color: #266e45; */
  border-left-width: 2px;
  border-left-color: white;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}

.button-text {
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-text > p {
  color: white;
  font-family: Folks, sans-serif;
  font-size: 17px !important;
}

.button-arrow {
  background: url("../../../App/images/arrow_Select_1.svg") no-repeat right;
  background-position: 80% 55%;
  border-radius: 0px 90px 90px 0px !important;
  background-size: 12px;
  position: relative;
  cursor: pointer;
}

.wrapper-btn {
  display: flex;
}

.special_hover:hover {
  background-color: #78b994;
}
