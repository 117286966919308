.container__contactadd {
  display: flex;
  width: 100%;
  flex-direction: column;
  /* background-color: lightcoral; */
}

.header_contactadd {
  flex: 0.2;
  /* background-color: lightcyan; */
}

.icon__contactadd {
  font-size: 40px;
  margin: 20px 0px 10px 0px;
}

.other_info_addcontact {
  flex: 0.6;
  display: flex;
  /* background-color: lightgreen; */
  align-items: flex-start;
  max-height: 70%;
}

.input_tag_addclient {
  /* background-color: lightcyan; */
}

.col-contact {
  /* background-color: lightgrey; */
  width: 220px;
}

.flex_triple_input {
  display: flex;
  margin-bottom: 15px;
}

.flex_triple_input_div1 {
  flex: 0.15;
  margin-right: 5px;
}

.flex_triple_input_div2 {
  flex: 0.6;
  margin-right: 5px;
}

.flex_triple_input_div3 {
  flex: 0.25;
}

.flex_triple_input_ind {
  padding: 5px !important;
}

.buttons-flex {
  margin: "50px 0 0 auto";
}

.conctadd_add_razon {
  flex: 0.1;
  width: 50%;
  max-width: 700px;
  margin-top: 15px;
}

.conctadd_add_razon {
  /* background-color: lightgrey; */
  flex: 0.1;
  width: 50%;
  max-width: 700px;
  margin-top: 15px;
  margin-bottom: 15 pxs;
}
