.test_indicator{
    width: 154px;
    height: 40px;
    background-color: rgba(255, 123, 0, 0.624);
    position: fixed;
    top: 0;
    right: 0px;
    border-radius: 10px 0px 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }