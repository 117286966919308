.wrapped__container_top_bar {
  width: 120%;
  margin-left: -10px;
  position: relative;
}

.top-bar {
  background-color: #266e45;
  height: 71px;
  /* margin-left: -26px; */
  margin-top: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  z-index: 1;

  margin-top: -10px;
  top: 0px;
}

.top-bar > h3 {
  color: white;
}

.search_div {
  background-color: #e5e5e5;
  height: 54px;
  display: flex;
  align-items: center;
  position: sticky;
  z-index: 1;
  top: 71px;
}

.search_container {
  display: flex;
  flex: 0.6;
  align-items: center;
  padding: 0 20px;
}

.rowTasks_title {
  height: 35px;
}

.rowTasks_title > td {
  border: none;
  font-size: 14px;
  text-align: left;
  padding-left: 7px;
  box-sizing: border-box;
  font-weight: bold;
  font-size: 16px;
}
