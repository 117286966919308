.countryDropdown{
    border-radius: 6px;
    height: 27px;
    font-size: 0.95rem;
    padding: 0 10px;
    border: 1px solid #b1b1b1;
    color: #383838;
    outline: none;
    box-sizing: border-box;
    min-width: 0px;
    width: 100%;
}