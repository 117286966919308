:root {
  --max_width_dashboard_col: 450px;
}

.main_container_dashboard {
  display: flex;
  /* background-color: aqua; */
  width: 100%;
  justify-content: space-around;
  position: relative;
}

.col1_dashboard {
  flex: 0.45;
  /* background-color: blue; */
}

.col2_dashboard {
  flex: 0.45;
  /* background-color: blueviolet; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.row0 {
  margin: 10px 0;
  width: 100%;
}

.row1 {
  height: 70px;
  display: flex;
  width: 100%;
  align-items: center;
  max-width: var(--max_width_dashboard_col);
}

.pending_logistic {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: var(--green);
  border-radius: 10px;
  box-sizing: border-box;
}

.bar_pending_logistic {
  height: 100%;
  width: 21px;
  background-color: var(--light_green);
  margin-right: 18px;
  border-radius: 10px 0 0 10px;
}

.circle_pending_logistic {
  background-color: var(--light_green);
  height: 40px;
  width: 40px;
  border-radius: 99px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.row2 {
  height: 230px;
  width: 100%;
  max-width: var(--max_width_dashboard_col);
  display: flex;
  align-items: center;
  margin-top: 5%;
}

.rented_containers {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 10px 0px rgba(120, 185, 148, 1);
  border-radius: 10px;
  box-sizing: border-box;
  padding: 15px 20px;
}

.row3 {
  width: 100%;
  max-width: var(--max_width_dashboard_col);
  display: flex;
  align-items: center;
  margin-top: 5%;
}

.pending_maintenance {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 10px 0px rgba(120, 185, 148, 1);
  border-radius: 10px;
  box-sizing: border-box;
  padding: 15px 20px;
}

.react-calendar button {
  background-color: none;
  font-size: 13px;
  color: black;
  display: block;
  border-radius: 0;
}

.react-calendar__navigation button {
  padding: 0;
}

.react-calendar button:hover {
  border-radius: 0;
}

.pendings_table {
  max-width: var(--max_width_dashboard_col);
  width: 100%;
  font-size: 13px;
  font-family: "folks";
}

.pending_table_scroll {
  max-width: var(--max_width_dashboard_col);
  max-height: 20%;
  overflow-y: scroll;
  margin-top: 50px;
  box-sizing: border-box;
  padding: 2px 0;
}

.pendings_table > tr {
  height: 1px;
  overflow: hidden;
}
.pendings_table > tr > td {
  padding: 5px;
  box-sizing: border-box;
  max-width: 1vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cell_nombre_cliente {
  text-align: left;
}

.table_type {
  width: 15%;
}

.table_client {
  max-width: 60%;
}

.table_time {
  width: 15%;
}

.flex_pie_chart {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}

.pie_container {
  flex: 0.45;
}

.pie_labels_container {
  flex: 0.5;
}

.bullet_label_pie {
  width: 15px;
  height: 15px;
  border-radius: 90px;
  margin-bottom: 15px;
  margin-right: 10px;
}

.pending_maintenance_table_scroll {
  width: 100%;
  max-height: 200px;
  overflow-y: scroll;
  margin-top: 20px;
  box-sizing: border-box;
  padding: 2px 0;
}

.pendings_maintenance_table {
  width: 100%;
  font-size: 13px;
  font-family: "folks";
}

.pendings_maintenance_table > tr {
  height: 1px;
  overflow: hidden;
}
.pendings_maintenance_table > tr > td {
  padding: 5px;
  box-sizing: border-box;
  max-width: 1vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cell_nombre_cliente {
  text-align: left;
}

.table_client_maintenance {
  width: 80%;
}
.table_date_maintenance {
  width: 20%;
}
