@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
/* RESET */
/* prettier-ignore */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
/* prettier-ignore */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/*DEFAULT */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* CUSTOM */
* {
  margin: 0;
}
@font-face {
  font-family: Folks;
  src: url(/static/media/Folks-Normal.9c8b499a.ttf);
}

@font-face {
  font-family: Folks_bold;
  src: url(/static/media/Folks-Bold.64a8f044.ttf);
}

:root {
  --green: #266e45;
  --light_green: #78b994;
}

h1 {
  font-size: 3rem;
  font-family: Folks, sans-serif;
  color: #266e45;
  font-weight: bold;
  letter-spacing: 1px;
}

h2 {
  font-size: 2.5rem;
  font-family: Folks, sans-serif;
  color: #266e45;
  font-weight: bold;
  letter-spacing: 1px;
}

h3 {
  font-size: 2rem;
  font-family: Folks, sans-serif;
  color: #266e45;
  font-weight: bold;
  letter-spacing: 1px;
}

h4 {
  font-size: 1.3rem;
  font-family: Folks_bold, sans-serif;
  color: #266e45;
  font-weight: bolder;
}

p {
  font-family: Roboto;
  color: #383838;
}

label {
  font-family: Roboto;
  color: #383838;
}

input[type="text"],
input[type="password"],
input[type="date"],
input[type="number"],
input[type="email"],
input[type="time"] {
  border-radius: 6px;
  height: 27px;
  font-size: 0.95rem;
  padding: 0 10px;
  border: 1px solid #b1b1b1;
  color: #383838;
  outline: none;
  box-sizing: border-box;
  min-width: 0px;
  width: 100%;
}

input[type="text"]:disabled {
  background-color: #ededed;
  color: rgb(163, 163, 163);
  border: 1px solid #e9e9e9;
}

input[type="date"],
input[type="time"] {
  font-size: 13px !important;
}

textarea {
  border-radius: 6px;
  font-size: 0.95rem;
  padding: 0 10px;
  border: 1px solid #b1b1b1;
  color: #383838;
  outline: none;
  box-sizing: border-box;
  min-width: 0px;
  width: 100%;
  min-height: 100px;
  font-family: Roboto;
}

button {
  padding: 7px 30px;
  border-radius: 99px;
  background-color: #266e45;
  border: 2px solid #266e45;
  color: white;
  font-family: Folks, sans-serif;
  font-size: 1.2rem;
  outline: none;
}

.button_appereance {
  border-radius: 99px;
  background-color: #266e45;
  border: 2px solid #266e45;
  color: white;
  outline: none;
}

.button_appereance:hover {
  cursor: pointer;
}

button:hover {
  background-color: white;
  color: #266e45;
  outline: none;
  border: 2px solid #266e45;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}

.button_delete {
  padding: 7px 30px;
  border-radius: 99px;
  background-color: #ff1616;
  border: 2px solid #ff1616;
  color: white;
  font-family: Folks, sans-serif;
  font-size: 1.2rem;
  outline: none;
}

.button_delete:hover {
  background-color: #ff1616;
  color: #000000;
  border: 2px solid #ff1616;
  outline: none;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}

select {
  border-radius: 10px;
  height: 32px;
  font-size: 1rem;
  padding: 0 10px;
  border: 1px solid #707070;
  color: #383838;
  outline: none;
  box-sizing: border-box;
  min-width: 0px;
  width: 100%;
}

.modal_select {
  border-radius: 6px;
  height: 27px;
  font-size: 0.95rem;
  padding: 0 10px;
  border: 1px solid #b1b1b1;
  color: #383838;
  outline: none;
  box-sizing: border-box;
  min-width: 0px;
  width: 100%;
}

.bold {
  font-weight: bold;
}

a {
  text-decoration: none;
}

table {
  font-family: Roboto, sans-serif;
}

th {
  font-weight: bold;
}

td {
  border: 1px solid #383838;
}

th {
  border: 1px solid #383838;
  padding: 5px;
}

/* GENERAL SECTIONS */

.container__clients {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  /* background-color: cadetblue; */
}

.img__icon {
  margin-bottom: 3%;
  /* background-color: chocolate; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 180px;
}

.img__icon > img {
  height: 115px;
  object-fit: contain;
  margin-bottom: 20px;
}

.img__icon_fa {
  font-size: 80px;
  margin-bottom: 20px;
}

.buttons_access {
  /* background-color: cyan; */
  display: flex;
  width: 100%;
  justify-content: center;
}

.square__button {
  background-color: #266e45;
  height: 157px;
  width: 173px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 30px 0px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
}

.square__button:hover {
  background-color: #78b994;
  transition: 0.2s ease-in-out;
}

.white {
  color: white;
}

.icon__clientshome {
  font-size: 60px;
}

.search__menu {
  width: 100%;
  display: flex;
  /* background-color: darkkhaki; */
  justify-content: center;
  padding: 1% 0%;
}

.search__by {
  display: flex;
  flex-direction: column;
  margin-right: 10%;
  /* background-color: greenyellow; */
  flex: 0.5 1;
}

.search__by__contain {
  align-self: flex-end;
}

.search__input {
  /* background-color: violet; */
  flex: 0.5 1;
}
.select__menu {
  /* background-color: tomato; */
  max-width: 300px;
}
.basic-single {
  max-height: 32px !important;
}

.tagSearch {
  margin-bottom: 15px;
}

.inputRadio {
  height: auto;
  margin-right: 10px;
  margin-bottom: 10px;
}

.screen {
  display: flex;
  box-sizing: border-box;
}

.content {
  margin: 10px;
  display: flex;
  width: 100%;
  /* background-color: burlywood; */
}

.col_white {
  color: white;
}

.col_green {
  color: #266e45;
  color: var(--green);
}

.flex_space_evenly {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}

.flex_space_between {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.simple_flex {
  display: flex;
  align-items: center;
}

/* This duplicate exists due to 'simple_flex' was used in a wrong way and we dont want to break thinks  */
.simple_flex_ {
  display: flex;
  align-items: center;
}


.simple_flex_column {
  display: flex;
  flex-direction: column;
}


.p_bold {
  font-weight: bold;
}

.blocked_color {
  color: #e6e6e6;
}

.blocked_color2 {
  background-color: #b0b0b0;
}

.input_center {
  text-align: center;
}

.simple_select {
  border-radius: 6px;
  height: 27px;
  font-size: 0.95rem;
  padding: 0 10px;
  border: 1px solid #b1b1b1;
  color: #383838;
  outline: none;
  box-sizing: border-box;
  min-width: 0px;
  width: 100%;
}
.wrapped__container_top_bar {
  width: 120%;
  margin-left: -10px;
  position: relative;
}

.top-bar {
  background-color: #266e45;
  height: 71px;
  /* margin-left: -26px; */
  margin-top: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;

  margin-top: -10px;
  top: 0px;
}

.top-bar > h3 {
  color: white;
}

.search_div {
  background-color: #e5e5e5;
  height: 54px;
  display: flex;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  top: 71px;
}

.search_container {
  display: flex;
  flex: 0.6 1;
  align-items: center;
  padding: 0 20px;
}

.rowTasks_title {
  height: 35px;
}

.rowTasks_title > td {
  border: none;
  font-size: 14px;
  text-align: left;
  padding-left: 7px;
  box-sizing: border-box;
  font-weight: bold;
  font-size: 16px;
}

.screen-container {
  margin: 0 auto;
  display: flex;
  height: 100vh;
  max-width: 1366px;
  align-content: center;
}

.vertical-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login {
  margin: 0 auto;
  width: 350px;
}

.login__square {
  background-color: white;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.16);

  margin: 0 auto;
  padding: 30px 50px;
  border-radius: 20px;
}

.login__logo {
  height: 100px;
  object-fit: contain;
  margin-bottom: 30px;
}

.login__title {
  text-align: center;
  margin-bottom: 25px;
}

.login__subtitle {
  margin-bottom: 10px;
  font-weight: normal;
}

.login__input {
  margin-bottom: 25px;
  width: 100%;
}

button {
  display: flex;
  margin: 0 auto;
}

.login__forgot {
  font-size: 0.7rem;
  font-family: Roboto;
  color: #383838;
  text-align: center;
  display: block;
  margin-bottom: 25px;
  cursor: pointer;
  text-decoration: underline;
}

.rectangle {
  background-color: #266e45;
  width: 154px;
  height: 97vh;
  margin-top: 12px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.rectangle__flex-home {
  flex: 0.2 1;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: blue; */
}

.rectangle__flex-others__center {
  flex: 0.6 1;
  display: flex;
  justify-content: center;
  /* background-color: indigo; */
}

.rectangle__flex-others__vertical {
  margin: 1rem 0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  /* background-color: blue; */
}

.rectangle__flex-logo {
  flex: 0.2 1;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: blue; */
}

.icon {
  font-size: 40px;
  cursor: pointer;
}

.sidebar_logo_cn {
  width: 98px;
  object-fit: contain;
  align-self: flex-end;
  margin-bottom: 15px;
}

.active {
  height: 55px;
  width: 55px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #78b994;
  transition: 0.3s ease-in-out;
}

.no-active {
  height: 55px;
  width: 55px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrap {
  height: 100%;
  max-height: 100vh;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrap_in_tasks {
  height: 100vh;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #266e45;
}

.container__dashboard {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  /* background-color: tomato; */
}

.header {
  width: 80%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  /* background-color: blue; */
  flex: 0.1 1;
}

.stadistics_sgto {
  flex: 0.3 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: aquamarine; */
  width: 80%;
}

.stadistics_total {
  /* background-color: indigo; */
  flex: 0.1 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stadistics_total_text {
  padding: 0px 30px;
  box-sizing: border-box;
  display: flex;
  width: 80%;
  height: 90%;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(120, 185, 148, 0.76);
  border-radius: 10px;
}

.stadistics_sgto_square {
  display: flex;
  background-color: white;
  height: 150px;
  align-items: center;
  border-radius: 10px;
  padding: 0px 30px;
  box-shadow: 0px 0px 10px 0px rgba(120, 185, 148, 1);
}

.circle-img {
  flex: 0.3 1;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 80px;
  width: 80px;
  border: 4px solid #266e45;
  border-radius: 50%;
  margin-right: 20px;
}

.stadistics_sgto_text {
  flex: 0.7 1;
  /* background-color: lightskyblue; */
  text-align: center;
}

.stadistics_graph {
  /* background-color: lightseagreen; */
  flex: 0.4 1;
  width: 80%;
  margin-bottom: 20px;
  box-shadow: 0px 0px 10px 0px rgba(120, 185, 148, 1);
  border-radius: 10px;
}

.container__userconfig {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  /* background-color: blue; */
}

.avatar {
  /* background-color: royalblue; */
  margin-bottom: 3%;
  display: flex;
  flex-direction: column;
}

.avatar__img {
  max-width: 120px;
  margin: 0 auto 15px auto;
}

.title {
  /* background-color: seagreen; */
  display: flex;
  align-items: center;
  width: 80%;
  margin-bottom: 3%;
}

.wrapper-info {
  display: flex;
  justify-content: center;
  width: 100%;
  /* background-color: teal; */
}

.info {
  /* background-color: tan; */
  max-width: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.margin__info {
  margin-right: 10%;
}

.labeltag {
  font-weight: bold;
  margin-bottom: 8px;
}

.label_tag_account {
  font-weight: bold;
  margin-bottom: 5%;
  margin-top: 5%;
  color: white;
}

.input__info {
  margin-bottom: 15px;
}

.input__info2 {
  margin-bottom: 15px;
  width: 100%;
  box-sizing: border-box;
}

.ubicacion-tag {
  margin-bottom: 15px;
  color: #266e45;
  font-weight: bold;
}

.em {
  margin-bottom: 15%;
}

.square__account {
  border-radius: 10px;
  background-color: #266e45;
  overflow: auto;
  margin-top: 15px;
}

.square__info {
  /* background-color: brown; */
  margin: 20px 20px 10px 20px;
}
.relative-button {
  position: relative;

  width: 100%;
}
.btn-userconfig {
  justify-content: center;
  display: flex;
  margin-top: 30px;
}

.MuiDialogTitle-root {
  display: flex;
  justify-content: center;
}

.logout-div {
  position: absolute;
  right: 90px;
  display: flex;
  justify-content: center;
  width: 60px;
  align-self: center;
  cursor: pointer;
}

.container__clientadd {
  /* background-color: grey; */
  width: 100%;
}

.img__factory__addclient {
  height: 72px;
  object-fit: contain;
  margin-bottom: 5px;
}

.container__clientadd > h4 {
  margin-bottom: 20px;
}

.inputRadio__addclient {
  margin-right: 5px;
  height: auto;
}

.clienteadd_radiobutton > label {
  margin-right: 20px;
}

.flex_razon_codigo {
  display: flex;
  /* background-color: hotpink; */
  margin-bottom: 15px;
  max-width: 700px;
}

.flex_other_info {
  display: flex;
  /* background-color: lightblue; */
}

.razon_social {
  /* background-color: khaki; */
  flex: 0.65 1;
  max-width: 500px;
  padding-right: 20px;
}

.code {
  flex: 0.35 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: teal; */
  max-width: 70px;
}

.flex_double_input {
  display: flex;
}

.nit {
  flex: 0.85 1;
}
.dv {
  flex: 0.15 1;
  margin-left: 10px;
}

.ind {
  flex: 0.15 1;
  margin-right: 10px;
}

.nit_input {
  width: 40px;
}

.select__addclient {
  margin-bottom: 15px;
  width: 100%;
}

.ind__input {
  width: 40px;
}

.column1__clientadd {
  /* background-color: lightcoral; */
  width: 220px;
}

.margin-end-button {
  margin: 0 0 0 70%;
}

.full-width {
  width: 100%;
}

.type_natural_info {
  display: flex;
  /* background-color: royalblue; */
  margin-top: 15px;
}

.col-contact {
  /* background-color: seagreen; */
}

.code_natural {
  max-width: 70px;
}

.flex-cumpleaños {
  display: flex;
  justify-content: space-between;
}


.btn-userconfigadd {
  justify-content: center;
  display: flex;
  margin-top: 30px;
}

.container__tasks {
  width: 120%;
  flex-direction: column;
  margin-left: -10px;
}

.search_container > input {
  max-width: 500px;
  margin-right: 10px;
}
.email_container > input {
  width: 170px;
  background-color: #383838;

  margin-left: 10px;
}

.guides {
  flex: 0.4 1;
  display: flex;
  justify-content: space-around;
}

.table_tasks {
  width: 100%;
}

.thcodigo {
  width: 6%;
}

.thestado {
  width: 3%;
}

.thCliente {
  width: 17%;
}

.thContacto {
  width: 17%;
}

.thUltimoSgto {
  width: 12%;
}

.thProxSgto {
  width: 12%;
}
.thPrioridad {
  width: 8%;
}
.thNoCot {
  width: 11%;
}
.thActualizar {
  width: 7%;
}
.thDetalle {
  width: 50%;
}

.rowHeaders {
  background-color: #383838;
  color: white;
}

.rowHeaders > th {
  border: none;
  position: -webkit-sticky;
  position: sticky;
  top: 125px;
  z-index: 1;

  background-color: #383838;
}

.rowTasks > td {
  border-top-width: 1px;
  border-top-color: #e5e5e5;
  border-bottom-width: 1px;
  border-bottom-color: #e5e5e5;
  font-size: 14px;
  border-left: none;
  border-right: none;
}

.wrapper-estado {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 3px 0 5px;
}

.wrapper-icon-codigo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.square_estado {
  width: 11px;
  height: 11px;
  border-radius: 90px;
}

.rowTasks {
  height: 35px;
}

.iconRow {
  font-size: 21px;
}

.detailRow {
  border: none !important;
  background-color: #e5e5e5;
  padding: 10px 15px;
}

.detail-line1 {
  display: flex;
  width: 100%;
  /* background-color: green; */
}

.p_detail {
  font-size: 13px;
  font-weight: bold;
  margin-left: 15px;
  /* background-color: pink; */
}
.line1__ultimoSgto,
.line1__proxSgto,
.line1__estado {
  display: flex;
  flex: 0.33 1;
  align-items: center;
}
.line1v1__estado {
  display: flex;
  flex: 0.2 1;
  align-items: center;
}
.line5__estado {
  display: flex;
  flex: 0.33 1;
  align-items: center;
  /* background-color: blue; */
}

.line1__ultimoSgto > input,
.line1__proxSgto > input,
.line1__estado > select,
.line1__estado > input {
  max-width: 150px;
  margin-left: 15px;
  height: 25px;
  font-size: 13px;
  /* background-color: blue; */
}
.lineemail__estado {
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;

  /* background-color: blue; */
}

.inputRadio__addclienttask {
  margin-right: 0px;
  height: auto;
}

.detail-line2 {
  display: flex;
  width: 100%;
  margin-top: 20px;
  min-height: 25px;
}

.detail-line3 {
  display: flex;
  margin-top: 10px;
  width: 100%;
  /* background-color: pink; */
}

.line3__ultimoSgtotxt {
  display: flex;
  flex: 0.5 1;
  align-items: center;
}

.line3__btn {
  flex: 0.5 1;
  vertical-align: middle;
}
.line4__btn {
  flex: 1 1;
  align-items: center;
  background-color: coral;
}

.txtareaUltimoSgto {
  max-width: 600px;
  resize: none;
  border: 1px solid #383838;
}

.detail-line4 {
  margin: 10px 0;
  text-align: left;
}
.detail-lineemail {
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: red;
}

.selectPrioridad {
  border: none;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  text-align: center;
  text-align-last: center;
  -moz-text-align-last: center;
  text-decoration: underline;
}

.rowHeaders > th:hover {
  text-decoration: underline;
}

.sub_guide {
  display: flex;
  align-items: center;
  /* background-color: brown; */
}

.contract_modal {
  background-color: white;
  box-sizing: border-box;
  padding: 15px;
}
.email_modal {
  background-color: white;
  box-sizing: border-box;
  padding: 15px;
  align-items: center;
  justify-content: center;
}
.email_modal_form {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: cadetblue; */
  margin: 15px 0;
}

.contract_modal_form {
  display: flex;
  justify-content: space-evenly;
  /* background-color: cadetblue; */
  margin: 15px 0;
}

.contract_modal_form_column {
  background-color: white;
  flex: 0.4 1;
}
.email_modal_form_column {
  background-color: white;
  flex: 1 1;
}

.p_contract_form {
  color: black;
  margin-bottom: 3px;
}

.input_contract_form {
  margin-bottom: 10px;
}

.email_modal_form_column_top {
  display: flex;
  justify-content: space-between;
}

.container__quoteadd {
  /* background-color: skyblue; */
  width: 100%;
}

.div-header-quote {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: thistle; */
  margin-bottom: 10px;
}

.header-logo-btns {
  display: flex;
  align-items: center;
}

.logo-name {
  margin-right: 50px;
}

.logo-name > img {
  margin-bottom: 10px;
}

.quoteadd_flex {
  display: flex;
  max-width: 900px;
}

.quoteadd_flex__7 {
  flex: 0.7 1;
}

.quoteadd_flex__3 {
  flex: 0.3 1;
  margin-left: 20px;
}

.quoteadd_flex__6 {
  flex: 0.6 1;
}

.quoteadd_flex__2 {
  flex: 0.2 1;
  margin-left: 20px;
}

.flex_custom_select_pdf{
  flex: 0.2 1;
  margin-top: 8px;
  margin-left: 20px;
  display: flex;
  align-items: center;
}

.quoteadd_flex__4 {
  flex: 0.4 1;
  margin-left: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.input__table {
  border-radius: 0px !important;
  border: 0px !important;
}

.select__table {
  border-radius: 0px !important;
  border: 0px !important;
  padding: 6px 10px;
}

td {
  text-align: center;
  vertical-align: middle;
}

td > textarea {
  min-height: 70px;
}

.subtotals {
  display: flex;
  flex-direction: column;
  margin: 25px 0px;
}

.subtotal {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 15px;
}

.label-subtotal {
  flex: 0.8 1;
  /* background-color: thistle; */
  text-align: end;
  margin-right: 10px;
}

.mount-subtotal {
  flex: 0.1 1;
  /* background-color: rgb(191, 216, 195); */
  text-align: center;
  min-width: 150px;
}

.div_delete {
  cursor: pointer;
}

.thno {
  width: 2%;
}
.thitem {
  width: 8%;
}
.thtipo {
  width: 9%;
}
.thproducto {
  width: 10%;
}
.thdescripcion {
  width: 19%;
}
.thcant {
  width: 2%;
}
.thvlrUnit {
  width: 9%;
}
.thvlrBruto {
  width: 11%;
}
.thdcto {
  width: 2%;
}
.thiva {
  width: 2%;
}
.thvlrTotal {
  width: 11%;
}
.thdelete {
  width: 2%;
}

.div_button_list {
  display: flex;
  flex-direction: row;
  max-height: 32px;
  background-color: #266e45;
  border-radius: 90px;
  padding: 0 10px;
  cursor: pointer;
}

.button-arrow > select {
  width: 80px;
  border-radius: 0px 90px 90px 0px !important;
  border-top: none !important;
  border-bottom: none !important;
  border-right: none;
  width: 20px;
  /* background-color: #266e45; */
  border-left-width: 2px;
  border-left-color: white;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}

.button-text {
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-text > p {
  color: white;
  font-family: Folks, sans-serif;
  font-size: 17px !important;
}

.button-arrow {
  background: url(/static/media/arrow_Select_1.cc91811e.svg) no-repeat right;
  background-position: 80% 55%;
  border-radius: 0px 90px 90px 0px !important;
  background-size: 12px;
  position: relative;
  cursor: pointer;
}

.wrapper-btn {
  display: flex;
}

.special_hover:hover {
  background-color: #78b994;
}

.header_container {
  display: flex;
  /* max-width: 1024px; */
  width: 100%;
  background-color: #515151;
  min-height: 218px;
  padding: 10px 0px;
}

.logopart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1;
}

.logopart > h4,
.flex_info_cotizacion > h4 {
  color: white;
  margin-bottom: 5px;
}

.regular_weight {
  font-weight: normal;
}

.infopart {
  flex: 1 1;
  padding-top: 30px;
  /* background-color: blueviolet; */
  margin-left: 50px;
}

.infopart > h3 {
  color: white;
  letter-spacing: 15px;
}

.flex_info_cotizacion {
  display: flex;
  /* background-color: brown; */
  margin-bottom: 10px;
}

.header_search {
  margin-bottom: 3%;
  padding-top: 3%;
  /* background-color: chocolate; */

  min-height: 60px;
}

.search__byradio {
  display: flex;
  flex-direction: row;
  margin-bottom: 3%;
  margin-right: 10%;
  flex: 0.8 1;
}

.search__by__containradio {
  align-self: space-between;
  display: flex;
  flex-direction: row;
  /* background-color: pink; */
}

.radio_input_clientesearch {
  margin-right: 20px;
}

.Label_search {
  font-weight: bold;
}

.container__search {
  /* background-color: grey; */
  width: 100%;
  position: relative;
}

input[type="radio"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  /* background-color:green ; */
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

.form_search {
  display: flex;
  flex-direction: row;
  /* background-color:pink ; */

  flex: 1 1;
}
.quoteadd_flex__search {
  flex: 0.3 1;
}

.input__info_search {
  width: 100%;
}
.col-contacsearch {
  /* background-color:brown ; */
  display: flex;
  flex: 1 1;

  flex-direction: column;
  width: 350px;
}

.btn_search {
  margin-top: 10px;
}

.labeltag2 {
  font-weight: bold;
  margin-bottom: 8px;
  /* background-color:brown ; */
}

.search_header_container {
  /* background-color: aqua; */
  width: 100%;
  max-width: 900px;
  margin-top: -10px;
}

.thestado_task {
  width: 3%;
}

.thcodigo {
  width: 5%;
}

.thNoCot_task {
  width: 7%;
}

.thCliente_task {
  width: 18%;
}

.thFecha {
  width: 10%;
}

.thContacto_task {
  width: 15%;
}

.thNro_contacto {
  width: 10%;
}

.thUltimoSgto_task {
  width: 10%;
}

.thMonto_task {
  width: 12%;
}

.thDetalle_task {
  width: 5%;
}
.table_search {
  width: 100%;
  margin-top: 20px;
}

.rowSearch > th {
  position: -webkit-sticky;
  position: sticky;
  z-index: 0;
  top: 0;
  background-color: #383838;
}

.container__contactadd {
  display: flex;
  width: 100%;
  flex-direction: column;
  /* background-color: lightcoral; */
}

.header_contactadd {
  flex: 0.2 1;
  /* background-color: lightcyan; */
}

.icon__contactadd {
  font-size: 40px;
  margin: 20px 0px 10px 0px;
}

.other_info_addcontact {
  flex: 0.6 1;
  display: flex;
  /* background-color: lightgreen; */
  align-items: flex-start;
  max-height: 70%;
}

.input_tag_addclient {
  /* background-color: lightcyan; */
}

.col-contact {
  /* background-color: lightgrey; */
  width: 220px;
}

.flex_triple_input {
  display: flex;
  margin-bottom: 15px;
}

.flex_triple_input_div1 {
  flex: 0.15 1;
  margin-right: 5px;
}

.flex_triple_input_div2 {
  flex: 0.6 1;
  margin-right: 5px;
}

.flex_triple_input_div3 {
  flex: 0.25 1;
}

.flex_triple_input_ind {
  padding: 5px !important;
}

.buttons-flex {
  margin: "50px 0 0 auto";
}

.conctadd_add_razon {
  flex: 0.1 1;
  width: 50%;
  max-width: 700px;
  margin-top: 15px;
}

.conctadd_add_razon {
  /* background-color: lightgrey; */
  flex: 0.1 1;
  width: 50%;
  max-width: 700px;
  margin-top: 15px;
  margin-bottom: 15 pxs;
}

:root {
  --max_width_dashboard_col: 450px;
}

.main_container_dashboard {
  display: flex;
  /* background-color: aqua; */
  width: 100%;
  justify-content: space-around;
  position: relative;
}

.col1_dashboard {
  flex: 0.45 1;
  /* background-color: blue; */
}

.col2_dashboard {
  flex: 0.45 1;
  /* background-color: blueviolet; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.row0 {
  margin: 10px 0;
  width: 100%;
}

.row1 {
  height: 70px;
  display: flex;
  width: 100%;
  align-items: center;
  max-width: 450px;
  max-width: var(--max_width_dashboard_col);
}

.pending_logistic {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: var(--green);
  border-radius: 10px;
  box-sizing: border-box;
}

.bar_pending_logistic {
  height: 100%;
  width: 21px;
  background-color: var(--light_green);
  margin-right: 18px;
  border-radius: 10px 0 0 10px;
}

.circle_pending_logistic {
  background-color: var(--light_green);
  height: 40px;
  width: 40px;
  border-radius: 99px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.row2 {
  height: 230px;
  width: 100%;
  max-width: 450px;
  max-width: var(--max_width_dashboard_col);
  display: flex;
  align-items: center;
  margin-top: 5%;
}

.rented_containers {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 10px 0px rgba(120, 185, 148, 1);
  border-radius: 10px;
  box-sizing: border-box;
  padding: 15px 20px;
}

.row3 {
  width: 100%;
  max-width: 450px;
  max-width: var(--max_width_dashboard_col);
  display: flex;
  align-items: center;
  margin-top: 5%;
}

.pending_maintenance {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 10px 0px rgba(120, 185, 148, 1);
  border-radius: 10px;
  box-sizing: border-box;
  padding: 15px 20px;
}

.react-calendar button {
  background-color: none;
  font-size: 13px;
  color: black;
  display: block;
  border-radius: 0;
}

.react-calendar__navigation button {
  padding: 0;
}

.react-calendar button:hover {
  border-radius: 0;
}

.pendings_table {
  max-width: 450px;
  max-width: var(--max_width_dashboard_col);
  width: 100%;
  font-size: 13px;
  font-family: "folks";
}

.pending_table_scroll {
  max-width: 450px;
  max-width: var(--max_width_dashboard_col);
  max-height: 20%;
  overflow-y: scroll;
  margin-top: 50px;
  box-sizing: border-box;
  padding: 2px 0;
}

.pendings_table > tr {
  height: 1px;
  overflow: hidden;
}
.pendings_table > tr > td {
  padding: 5px;
  box-sizing: border-box;
  max-width: 1vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cell_nombre_cliente {
  text-align: left;
}

.table_type {
  width: 15%;
}

.table_client {
  max-width: 60%;
}

.table_time {
  width: 15%;
}

.flex_pie_chart {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}

.pie_container {
  flex: 0.45 1;
}

.pie_labels_container {
  flex: 0.5 1;
}

.bullet_label_pie {
  width: 15px;
  height: 15px;
  border-radius: 90px;
  margin-bottom: 15px;
  margin-right: 10px;
}

.pending_maintenance_table_scroll {
  width: 100%;
  max-height: 200px;
  overflow-y: scroll;
  margin-top: 20px;
  box-sizing: border-box;
  padding: 2px 0;
}

.pendings_maintenance_table {
  width: 100%;
  font-size: 13px;
  font-family: "folks";
}

.pendings_maintenance_table > tr {
  height: 1px;
  overflow: hidden;
}
.pendings_maintenance_table > tr > td {
  padding: 5px;
  box-sizing: border-box;
  max-width: 1vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cell_nombre_cliente {
  text-align: left;
}

.table_client_maintenance {
  width: 80%;
}
.table_date_maintenance {
  width: 20%;
}

.react-calendar {
  width: 300px;
  max-width: 100%;
  background: white;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  box-shadow: 0px 0px 10px 0px rgba(120, 185, 148, 1);
  border-radius: 10px;
  box-sizing: border-box;
  padding: 10px;
}

.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 44px;
  margin-bottom: 5px;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575 !important;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0 !important;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6 !important;
}
.react-calendar__tile--now {
  background: var(--light_green) !important;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: var(--light_green) !important;
}
.react-calendar__tile--hasActive {
  background: #76baff !important;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff !important;
}
.react-calendar__tile--active {
  background: #006edc !important;
  color: white !important;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff !important;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.loading_container {
  background-color: rgba(0, 0, 0, 0.095);
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: visibility 0.3s linear, opacity 0.3s linear;
}

.visible {
  visibility: visible;
  opacity: 1;
}

.hidden {
  visibility: hidden;
  opacity: 0;
}

.loading_icon {
  font-size: 7em;
  color: var(--light_green);
}

.entrances__container {
  width: 120%;
  margin-left: -10px;
  position: relative;
}

.guides_2 {
  flex: 0.4 1;
  display: flex;
  box-sizing: border-box;
  padding: 0 3%;
  justify-content: flex-end;
}

.guides_2 > .sub_guide {
  margin-left: 5%;
}

.square_plus {
  width: 23px;
  height: 23px;
  border-radius: 6px;
  background-color: var(--green);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  cursor: pointer;
}

.inactive_background {
  background-color: #e6e6e6;
}

.title_modal_detail_entrance {
  height: 15%;
  position: relative;
  margin-bottom: 20px;
}

.title_modal_detail_entrance > h4 {
  text-align: center;
  margin: 1% 0;
}

.x_modal_detail {
  position: absolute;
  top: 25%;
  right: 1%;
}

.content_modal_detail_entrance {
  margin: 0 10%;
}

.flex_content_modal_detail_entrance {
  display: flex;
  margin: 1% 0 2% 0;
}

.flex_content_modal_detail_entrance > div {
  margin-right: 20px;
}

.flex_content_modal_detail_entrance > div:last-of-type {
  max-width: 250px;
}

.entrance_detail__container {
  width: 100%;
  position: relative;
}

.detail_labels_container {
  display: flex;
  margin: 10px 0 20px 0;
}

.detail_labels_col {
  flex: 0.28 1;
}

.flex_label_input_entrance {
  display: flex;
  margin-bottom: 5px;
}

.flex_label_input_entrance > :first-child {
  flex: 0.2 1;
  /* background-color: blue; */
}

.flex_label_input_entrance > :last-child {
  flex: 0.8 1;
  /* background-color: cadetblue; */
}

.approved_items_container {
  display: flex;
}

.approved_items_col {
  margin: 20px 0;
}

.approved_items_col:first-child {
  flex: 0.4 1;
  max-width: 450px;
  margin-right: 50px;
}

.approved_items_col:last-child {
  flex: 0.6 1;
  max-width: 1500px;
}

.table_approved_items {
  width: 100%;
  border-collapse: collapse;
}

.table_approved_items > tr > th,
.table_approved_items > tr > td,
.table_scheduled_requirements > tr > th,
.table_scheduled_requirements > tr > td {
  border: none !important;
}

.flex_info_add {
  display: flex;
  margin-bottom: 10px;
}
.flex_info_add > div {
  margin-right: 20px;
}
.origen_div {
  width: 40%;
}

.table_scheduled_requirements {
  width: 100%;
  max-width: 800px;
  margin-top: 15px;
}

.alert_container {
  position: absolute;
  width: 100%;
  transition: visibility 0.3s linear, opacity 0.3s linear;
}

.select_table {
  height: auto;
  font-size: 14px;
  border: 0px;
  text-align-last: center;
  padding: 0px !important;
}

.input_table {
  height: auto !important;
  font-size: 14px !important;
  border: 0px !important;
  width: auto !important;
  padding: 0px !important;
}

::-webkit-calendar-picker-indicator {
  margin-left: 0px;
}

.open_logistic {
  background-color: #e5e5e5;
  border: 0px;
  text-align: left;
}

.detail_logistic_container {
  width: 100%;
  display: flex;
  padding: 10px 20px;
  box-sizing: border-box;
}

.detail_logistic_container_left {
  flex: 0.3 1;
  display: flex;
  margin-right: 5%;
  /* background-color: aquamarine; */
}

.detail_logistic_container_flex {
  /* background-color: blueviolet; */
  width: 100%;
  box-sizing: border-box;
  padding: 8px 10px;
}

.bar_detail_logistic {
  height: 100%;
  border-radius: 99px;
  width: 8px;
}

.select_detail_logistic {
  width: auto;
  max-width: 200px;
  height: 25px;
  border-radius: 5px;
  border: 0px;
  padding: 10px;
  box-sizing: border-box;
}

.input_detail_logistic {
  border-radius: 5px !important;
  border: 0px !important;
  height: 25px !important;
  font-size: 13px !important;
  padding: 0 3px;
  box-sizing: border-box;
}

.textarea_detail_logistic {
  width: 100% !important;
  border: 0px;
  font-size: 13px;
  font-family: Roboto, sans-serif;
  padding: 5px 8px;
}
.detail_logistic_container_flex > .simple_flex {
  margin: 5px 0;
  min-height: 25px;
}

.simple_flex > p {
  font-size: 13px;
  max-width: 137.49px !important;
}

.detail_logistic_container_flex > .simple_flex > :first-child {
  flex: 0.4 1;
  max-width: 142px;
  font-weight: bold;
  /* background-color: brown; */
}

.detail_logistic_container_flex > .simple_flex > :last-child {
  flex: 0.6 1;
  /* background-color: chartreuse; */
  max-width: 200px;
}

.detail_logistic_container_center {
  display: flex;
  flex: 0.3 1;
  /* background-color: chartreuse; */
}

.detail_logistic_container_right {
  display: flex;
  flex: 0.15 1;
  /* background-color: chartreuse; */
}

.flex_checkbox_detail_logistic {
  margin-top: 31px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.flex_checkbox_detail_logistic > p {
  font-size: 13px;
  margin-left: 7px;
}

.montacarga_section {
  max-width: 145px;
  margin-left: 20px;
  margin-top: 20px;
}

.montacarga_section > p {
  font-size: 13px;
  margin-bottom: 6px;
}

.label_radio_detail_logistic {
  font-size: 13px;
  margin-left: 5px;
}

input[type="radio"]:checked:after {
  border: 0px;
}

.teste {
}

.main_container_movements {
  /* background-color: aqua; */
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  position: relative;
}

.flex_mov_sec1 {
  display: flex;
  max-width: 470px;
  justify-content: space-between;
  margin-top: 2%;
  margin-bottom: 1%;
}

.flex_mov_sec1_col {
  max-width: 216.4px;
  width: 100%;
}

.p_mov {
  font-weight: bold;
  margin-bottom: 5px;
}

.input_mov {
  margin-bottom: 15px;
}

.flex_mov_sec2 {
  display: flex;
  max-width: 470px;
  justify-content: space-between;
  margin-top: 1.5%;
  margin-bottom: 1%;
}

.mov_sec3 {
  max-width: 470px;
}

.mov_sec3 > textarea {
  margin-top: 15px;
  padding: 2px 8px;
  box-sizing: border-box;
}

.mov_sec3 > button {
  margin: 40px 0 0 auto;
}

.modal_return_container {
  padding: 25px 25px 0 25px;
}

.flex_checkbox_return_modal {
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.flex_checkbox_return_modal:last-of-type {
  margin-bottom: 0px;
}

.guides_stock {
  flex: 0.4 1;
  display: flex;
  box-sizing: border-box;
  padding: 0 3%;
  justify-content: space-between;
}

.simple_flex > .sub_guide:first-of-type {
  margin-right: 30px;
}

.modal_maintenance_container {
}

.flex_label_input {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  max-width: 290px;
  justify-content: space-between;
}

.label_input_modal {
  font-size: 14px;
  font-weight: bold;
  flex: 0.4 1;
}

.input_modal {
  flex: 0.6 1;
  border-radius: 5px !important;
  height: 25px !important;
  font-size: 13px !important;
  padding: 0 10px;
  box-sizing: border-box !important;
}

.input_ref {
  border-radius: 5px !important;
  height: 25px !important;
  font-size: 13px !important;
  padding: 0 10px;
  box-sizing: border-box;
}

.container_inputs_ref {
  display: flex;
  flex: 0.6 1;
  justify-content: space-between;
}

.select_modal {
  flex: 0.6 1;
  height: 25px;
  border-radius: 5px;
  border: 0px;
  padding: 0 10px;
  box-sizing: border-box;
  border: 1px solid #797979;
}

.test_indicator{
    width: 154px;
    height: 40px;
    background-color: rgba(255, 123, 0, 0.624);
    position: fixed;
    top: 0;
    right: 0px;
    border-radius: 10px 0px 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }
.search_filters_container {
  display: flex;
  margin: 15px 0;
}

.filters_col {
  flex: 0.25 1;
  justify-content: flex-end;
  margin-right: 5%;
}

.filters_col > div {
  margin-bottom: 15px;
}

.filters_col > div:last-of-type {
  margin-bottom: 0px;
}

.filters_col:last-of-type {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.razon_social_container_search {
  position: relative;
  z-index: 2;
}

.guides_tools {
  flex: 0.5 1;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  height: 60%;
}

.sub_guide_tools {
  display: flex;
  align-items: center;
  flex: 0.3 1;
  margin-left: 2px;
}

.inputselect {
  background-color: transparent;
  background-color: initial;
  cursor: default;
  -webkit-appearance: auto;
     -moz-appearance: auto;
          appearance: auto;
  box-sizing: border-box;
  border: none;
  padding: 0;
  padding: initial;
  border: initial;
}

.nodisponibles:checked:after {
  background-color: red;
  border: none;
}
.disponibles:checked:after {
  background-color: green;
  border: none;
}

.nofuncional:checked:after {
  background-color: orange;
  border: none;
}

.square_agregar_tools {
  width: 90%;
  height: 100%;
  border-radius: 6px;
  background-color: var(--green);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 auto;
  cursor: pointer;
  max-width: 125px;
}

.block_background {
  background-color: #b0b0b0 !important;
}


.agregar_label_btn {
  font-size: 1.15rem;
  font-family: Folks_bold, sans-serif;
}

.flex_label_info_mtto {
  display: flex;
  margin-bottom: 15px;
}

.flex_label_info_mtto > :first-child {
  flex: 0.6 1;
}

.flex_label_info_mtto > :last-child {
  flex: 0.4 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Modal Tools Add /Edit */
.row_modal_herramientas {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
}

.p_modal_herramientas {
  margin-bottom: 7px;
}

.row_modal_herramientas > div:nth-child(1) {
  flex: 0.22 1;
}
.row_modal_herramientas > div:nth-child(2) {
  flex: 0.46 1;
}
.row_modal_herramientas > div:nth-child(3) {
  flex: 0.22 1;
}

.row2_modal_herramientas {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
}

.row2_modal_herramientas > div:nth-child(1) {
  flex: 0.22 1;
}
.row2_modal_herramientas > div:nth-child(2) {
  flex: 0.46 1;
}
.row2_modal_herramientas > div:nth-child(3) {
  flex: 0.22 1;
}

.input_select_style {
  border-color: #d5d5d5 !important;
  border-width: 1px;
  border-radius: 5px !important;
  font-size: 13px !important;
  height: 27px !important;
}

.button_container_modal_herramientas {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
/* Modal Devolucion */

.row_modal {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
}

.row_modal > div:nth-child(1) {
  flex: 0.45 1;
}
.row_modal > div:nth-child(2) {
  flex: 0.45 1;
}

.obs_devolucion_container {
  flex: 0.6 1 !important;
}

.es_funcional_devolucion_container {
  flex: 0.3 1 !important;
}

.obs_devolucion_container > textarea {
  min-height: 70px !important;
}

.obs_salida_textarea {
  min-height: 70px !important;
}

.col1_herramientas_modal {
  display: flex;
  flex-direction: column;
  width: 22%;
  margin-right: 5%;
  justify-content: space-between;
}

.col2_herramientas_modal {
  display: flex;
  flex-direction: column;
  width: 46%;
  margin-right: 5%;
  justify-content: space-between;
}

.col3_herramientas_modal {
  width: 22%;
  height: 99%;
}

.managed:checked:after {
  background-color: green;
  border: none;
  width: 12px !important;
  height: 12px !important;
  top: 0 !important;
  left: 0 !important;
}

.unmanaged:checked:after {
  background-color: red;
  width: 12px !important;
  height: 12px !important;
  top: 0 !important;
  left: 0 !important;
  border: none;
}

.in_process:checked:after {
  background-color: blue;
  width: 12px !important;
  height: 12px !important;
  top: 0 !important;
  left: 0 !important;
  border: none;
}


.main_container_orders_detail {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  position: relative;
}

.simple_square_plus {
  width: 23px;
  height: 23px;
  border-radius: 6px;
  background-color: var(--green);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.my_input:disabled {
  background-color: #f2f2f2;
  color: #7b7b7b;
  border: 1px solid #e9e9e9;
}

.dynamic_cost_centers {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}





.input-lock{
    background-color: #b1b1b1;
}

.main_container_group_management {
    box-sizing: border-box;
    padding: 10px;
    position: relative;
  }
.pagination {
  color: "green";
  display: flex;
}

.page-item {
  font-weight: bold;
  padding: 2px 8px;
  cursor: pointer;
  color: #383838;
}

.active-page {
  background-color: #266e45;
  color: white;
  border-radius: 99px;
}

.countryDropdown{
    border-radius: 6px;
    height: 27px;
    font-size: 0.95rem;
    padding: 0 10px;
    border: 1px solid #b1b1b1;
    color: #383838;
    outline: none;
    box-sizing: border-box;
    min-width: 0px;
    width: 100%;
}
.inside_po_issues_table {
  border-collapse: collapse;
  width: 80%;
  border: none;
}

.inside_po_issues_table > tr > th,
.inside_po_issues_table > tr > td {
  border: none;
  text-align: center;
  padding: 5px;
}

.blocked_button_color {
  background-color: #c6c6c6 !important;
}

.th_detail_oc > th {
  border: none;
  position: -webkit-sticky;
  position: sticky;
  top: 180px;
  z-index: 1;

  background-color: #383838;
}
