.guides_stock {
  flex: 0.4;
  display: flex;
  box-sizing: border-box;
  padding: 0 3%;
  justify-content: space-between;
}

.simple_flex > .sub_guide:first-of-type {
  margin-right: 30px;
}
