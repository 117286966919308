.container__clientadd {
  /* background-color: grey; */
  width: 100%;
}

.img__factory__addclient {
  height: 72px;
  object-fit: contain;
  margin-bottom: 5px;
}

.container__clientadd > h4 {
  margin-bottom: 20px;
}

.inputRadio__addclient {
  margin-right: 5px;
  height: auto;
}

.clienteadd_radiobutton > label {
  margin-right: 20px;
}

.flex_razon_codigo {
  display: flex;
  /* background-color: hotpink; */
  margin-bottom: 15px;
  max-width: 700px;
}

.flex_other_info {
  display: flex;
  /* background-color: lightblue; */
}

.razon_social {
  /* background-color: khaki; */
  flex: 0.65;
  max-width: 500px;
  padding-right: 20px;
}

.code {
  flex: 0.35;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: teal; */
  max-width: 70px;
}

.flex_double_input {
  display: flex;
}

.nit {
  flex: 0.85;
}
.dv {
  flex: 0.15;
  margin-left: 10px;
}

.ind {
  flex: 0.15;
  margin-right: 10px;
}

.nit_input {
  width: 40px;
}

.select__addclient {
  margin-bottom: 15px;
  width: 100%;
}

.ind__input {
  width: 40px;
}

.column1__clientadd {
  /* background-color: lightcoral; */
  width: 220px;
}

.margin-end-button {
  margin: 0 0 0 70%;
}

.full-width {
  width: 100%;
}

.type_natural_info {
  display: flex;
  /* background-color: royalblue; */
  margin-top: 15px;
}

.col-contact {
  /* background-color: seagreen; */
}

.code_natural {
  max-width: 70px;
}

.flex-cumpleaños {
  display: flex;
  justify-content: space-between;
}


.btn-userconfigadd {
  justify-content: center;
  display: flex;
  margin-top: 30px;
}
