.select_table {
  height: auto;
  font-size: 14px;
  border: 0px;
  text-align-last: center;
  padding: 0px !important;
}

.input_table {
  height: auto !important;
  font-size: 14px !important;
  border: 0px !important;
  width: auto !important;
  padding: 0px !important;
}

::-webkit-calendar-picker-indicator {
  margin-left: 0px;
}

.open_logistic {
  background-color: #e5e5e5;
  border: 0px;
  text-align: left;
}

.detail_logistic_container {
  width: 100%;
  display: flex;
  padding: 10px 20px;
  box-sizing: border-box;
}

.detail_logistic_container_left {
  flex: 0.3;
  display: flex;
  margin-right: 5%;
  /* background-color: aquamarine; */
}

.detail_logistic_container_flex {
  /* background-color: blueviolet; */
  width: 100%;
  box-sizing: border-box;
  padding: 8px 10px;
}

.bar_detail_logistic {
  height: 100%;
  border-radius: 99px;
  width: 8px;
}

.select_detail_logistic {
  width: auto;
  max-width: 200px;
  height: 25px;
  border-radius: 5px;
  border: 0px;
  padding: 10px;
  box-sizing: border-box;
}

.input_detail_logistic {
  border-radius: 5px !important;
  border: 0px !important;
  height: 25px !important;
  font-size: 13px !important;
  padding: 0 3px;
  box-sizing: border-box;
}

.textarea_detail_logistic {
  width: 100% !important;
  border: 0px;
  font-size: 13px;
  font-family: Roboto, sans-serif;
  padding: 5px 8px;
}
.detail_logistic_container_flex > .simple_flex {
  margin: 5px 0;
  min-height: 25px;
}

.simple_flex > p {
  font-size: 13px;
  max-width: 137.49px !important;
}

.detail_logistic_container_flex > .simple_flex > :first-child {
  flex: 0.4;
  max-width: 142px;
  font-weight: bold;
  /* background-color: brown; */
}

.detail_logistic_container_flex > .simple_flex > :last-child {
  flex: 0.6;
  /* background-color: chartreuse; */
  max-width: 200px;
}

.detail_logistic_container_center {
  display: flex;
  flex: 0.3;
  /* background-color: chartreuse; */
}

.detail_logistic_container_right {
  display: flex;
  flex: 0.15;
  /* background-color: chartreuse; */
}

.flex_checkbox_detail_logistic {
  margin-top: 31px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.flex_checkbox_detail_logistic > p {
  font-size: 13px;
  margin-left: 7px;
}

.montacarga_section {
  max-width: 145px;
  margin-left: 20px;
  margin-top: 20px;
}

.montacarga_section > p {
  font-size: 13px;
  margin-bottom: 6px;
}

.label_radio_detail_logistic {
  font-size: 13px;
  margin-left: 5px;
}

input[type="radio"]:checked:after {
  border: 0px;
}

.teste {
}
