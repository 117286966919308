.guides_tools {
  flex: 0.5;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  height: 60%;
}

.sub_guide_tools {
  display: flex;
  align-items: center;
  flex: 0.3;
  margin-left: 2px;
}

.inputselect {
  background-color: initial;
  cursor: default;
  appearance: auto;
  box-sizing: border-box;
  border: none;
  padding: initial;
  border: initial;
}

.nodisponibles:checked:after {
  background-color: red;
  border: none;
}
.disponibles:checked:after {
  background-color: green;
  border: none;
}

.nofuncional:checked:after {
  background-color: orange;
  border: none;
}

.square_agregar_tools {
  width: 90%;
  height: 100%;
  border-radius: 6px;
  background-color: var(--green);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 auto;
  cursor: pointer;
  max-width: 125px;
}

.block_background {
  background-color: #b0b0b0 !important;
}


.agregar_label_btn {
  font-size: 1.15rem;
  font-family: Folks_bold, sans-serif;
}

.flex_label_info_mtto {
  display: flex;
  margin-bottom: 15px;
}

.flex_label_info_mtto > :first-child {
  flex: 0.6;
}

.flex_label_info_mtto > :last-child {
  flex: 0.4;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Modal Tools Add /Edit */
.row_modal_herramientas {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
}

.p_modal_herramientas {
  margin-bottom: 7px;
}

.row_modal_herramientas > div:nth-child(1) {
  flex: 0.22;
}
.row_modal_herramientas > div:nth-child(2) {
  flex: 0.46;
}
.row_modal_herramientas > div:nth-child(3) {
  flex: 0.22;
}

.row2_modal_herramientas {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
}

.row2_modal_herramientas > div:nth-child(1) {
  flex: 0.22;
}
.row2_modal_herramientas > div:nth-child(2) {
  flex: 0.46;
}
.row2_modal_herramientas > div:nth-child(3) {
  flex: 0.22;
}

.input_select_style {
  border-color: #d5d5d5 !important;
  border-width: 1px;
  border-radius: 5px !important;
  font-size: 13px !important;
  height: 27px !important;
}

.button_container_modal_herramientas {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
/* Modal Devolucion */

.row_modal {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
}

.row_modal > div:nth-child(1) {
  flex: 0.45;
}
.row_modal > div:nth-child(2) {
  flex: 0.45;
}

.obs_devolucion_container {
  flex: 0.6 !important;
}

.es_funcional_devolucion_container {
  flex: 0.3 !important;
}

.obs_devolucion_container > textarea {
  min-height: 70px !important;
}

.obs_salida_textarea {
  min-height: 70px !important;
}

.col1_herramientas_modal {
  display: flex;
  flex-direction: column;
  width: 22%;
  margin-right: 5%;
  justify-content: space-between;
}

.col2_herramientas_modal {
  display: flex;
  flex-direction: column;
  width: 46%;
  margin-right: 5%;
  justify-content: space-between;
}

.col3_herramientas_modal {
  width: 22%;
  height: 99%;
}
