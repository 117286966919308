.entrance_detail__container {
  width: 100%;
  position: relative;
}

.detail_labels_container {
  display: flex;
  margin: 10px 0 20px 0;
}

.detail_labels_col {
  flex: 0.28;
}

.flex_label_input_entrance {
  display: flex;
  margin-bottom: 5px;
}

.flex_label_input_entrance > :first-child {
  flex: 0.2;
  /* background-color: blue; */
}

.flex_label_input_entrance > :last-child {
  flex: 0.8;
  /* background-color: cadetblue; */
}

.approved_items_container {
  display: flex;
}

.approved_items_col {
  margin: 20px 0;
}

.approved_items_col:first-child {
  flex: 0.4;
  max-width: 450px;
  margin-right: 50px;
}

.approved_items_col:last-child {
  flex: 0.6;
  max-width: 1500px;
}

.table_approved_items {
  width: 100%;
  border-collapse: collapse;
}

.table_approved_items > tr > th,
.table_approved_items > tr > td,
.table_scheduled_requirements > tr > th,
.table_scheduled_requirements > tr > td {
  border: none !important;
}

.flex_info_add {
  display: flex;
  margin-bottom: 10px;
}
.flex_info_add > div {
  margin-right: 20px;
}
.origen_div {
  width: 40%;
}

.table_scheduled_requirements {
  width: 100%;
  max-width: 800px;
  margin-top: 15px;
}

.alert_container {
  position: absolute;
  width: 100%;
  transition: visibility 0.3s linear, opacity 0.3s linear;
}
