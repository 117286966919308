.loading_container {
  background-color: rgba(0, 0, 0, 0.095);
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: visibility 0.3s linear, opacity 0.3s linear;
}

.visible {
  visibility: visible;
  opacity: 1;
}

.hidden {
  visibility: hidden;
  opacity: 0;
}

.loading_icon {
  font-size: 7em;
  color: var(--light_green);
}
