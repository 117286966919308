.managed:checked:after {
  background-color: green;
  border: none;
  width: 12px !important;
  height: 12px !important;
  top: 0 !important;
  left: 0 !important;
}

.unmanaged:checked:after {
  background-color: red;
  width: 12px !important;
  height: 12px !important;
  top: 0 !important;
  left: 0 !important;
  border: none;
}

.in_process:checked:after {
  background-color: blue;
  width: 12px !important;
  height: 12px !important;
  top: 0 !important;
  left: 0 !important;
  border: none;
}


.main_container_orders_detail {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  position: relative;
}

.simple_square_plus {
  width: 23px;
  height: 23px;
  border-radius: 6px;
  background-color: var(--green);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.my_input:disabled {
  background-color: #f2f2f2;
  color: #7b7b7b;
  border: 1px solid #e9e9e9;
}

.dynamic_cost_centers {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}
