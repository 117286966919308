.main_container_movements {
  /* background-color: aqua; */
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  position: relative;
}

.flex_mov_sec1 {
  display: flex;
  max-width: 470px;
  justify-content: space-between;
  margin-top: 2%;
  margin-bottom: 1%;
}

.flex_mov_sec1_col {
  max-width: 216.4px;
  width: 100%;
}

.p_mov {
  font-weight: bold;
  margin-bottom: 5px;
}

.input_mov {
  margin-bottom: 15px;
}

.flex_mov_sec2 {
  display: flex;
  max-width: 470px;
  justify-content: space-between;
  margin-top: 1.5%;
  margin-bottom: 1%;
}

.mov_sec3 {
  max-width: 470px;
}

.mov_sec3 > textarea {
  margin-top: 15px;
  padding: 2px 8px;
  box-sizing: border-box;
}

.mov_sec3 > button {
  margin: 40px 0 0 auto;
}
