.container__userconfig {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  /* background-color: blue; */
}

.avatar {
  /* background-color: royalblue; */
  margin-bottom: 3%;
  display: flex;
  flex-direction: column;
}

.avatar__img {
  max-width: 120px;
  margin: 0 auto 15px auto;
}

.title {
  /* background-color: seagreen; */
  display: flex;
  align-items: center;
  width: 80%;
  margin-bottom: 3%;
}

.wrapper-info {
  display: flex;
  justify-content: center;
  width: 100%;
  /* background-color: teal; */
}

.info {
  /* background-color: tan; */
  max-width: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.margin__info {
  margin-right: 10%;
}

.labeltag {
  font-weight: bold;
  margin-bottom: 8px;
}

.label_tag_account {
  font-weight: bold;
  margin-bottom: 5%;
  margin-top: 5%;
  color: white;
}

.input__info {
  margin-bottom: 15px;
}

.input__info2 {
  margin-bottom: 15px;
  width: 100%;
  box-sizing: border-box;
}

.ubicacion-tag {
  margin-bottom: 15px;
  color: #266e45;
  font-weight: bold;
}

.em {
  margin-bottom: 15%;
}

.square__account {
  border-radius: 10px;
  background-color: #266e45;
  overflow: auto;
  margin-top: 15px;
}

.square__info {
  /* background-color: brown; */
  margin: 20px 20px 10px 20px;
}
.relative-button {
  position: relative;

  width: 100%;
}
.btn-userconfig {
  justify-content: center;
  display: flex;
  margin-top: 30px;
}

.MuiDialogTitle-root {
  display: flex;
  justify-content: center;
}

.logout-div {
  position: absolute;
  right: 90px;
  display: flex;
  justify-content: center;
  width: 60px;
  align-self: center;
  cursor: pointer;
}
