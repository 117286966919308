.screen-container {
  margin: 0 auto;
  display: flex;
  height: 100vh;
  max-width: 1366px;
  align-content: center;
}

.vertical-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login {
  margin: 0 auto;
  width: 350px;
}

.login__square {
  background-color: white;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.16);

  margin: 0 auto;
  padding: 30px 50px;
  border-radius: 20px;
}

.login__logo {
  height: 100px;
  object-fit: contain;
  margin-bottom: 30px;
}

.login__title {
  text-align: center;
  margin-bottom: 25px;
}

.login__subtitle {
  margin-bottom: 10px;
  font-weight: normal;
}

.login__input {
  margin-bottom: 25px;
  width: 100%;
}

button {
  display: flex;
  margin: 0 auto;
}

.login__forgot {
  font-size: 0.7rem;
  font-family: Roboto;
  color: #383838;
  text-align: center;
  display: block;
  margin-bottom: 25px;
  cursor: pointer;
  text-decoration: underline;
}
