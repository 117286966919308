.inside_po_issues_table {
  border-collapse: collapse;
  width: 80%;
  border: none;
}

.inside_po_issues_table > tr > th,
.inside_po_issues_table > tr > td {
  border: none;
  text-align: center;
  padding: 5px;
}

.blocked_button_color {
  background-color: #c6c6c6 !important;
}

.th_detail_oc > th {
  border: none;
  position: sticky;
  top: 180px;
  z-index: 1;

  background-color: #383838;
}