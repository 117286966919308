.entrances__container {
  width: 120%;
  margin-left: -10px;
  position: relative;
}

.guides_2 {
  flex: 0.4;
  display: flex;
  box-sizing: border-box;
  padding: 0 3%;
  justify-content: flex-end;
}

.guides_2 > .sub_guide {
  margin-left: 5%;
}

.square_plus {
  width: 23px;
  height: 23px;
  border-radius: 6px;
  background-color: var(--green);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  cursor: pointer;
}

.inactive_background {
  background-color: #e6e6e6;
}
