.search_filters_container {
  display: flex;
  margin: 15px 0;
}

.filters_col {
  flex: 0.25;
  justify-content: flex-end;
  margin-right: 5%;
}

.filters_col > div {
  margin-bottom: 15px;
}

.filters_col > div:last-of-type {
  margin-bottom: 0px;
}

.filters_col:last-of-type {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.razon_social_container_search {
  position: relative;
  z-index: 2;
}
